import { createContext, useContext, useState } from "react";
import PropTypes from "prop-types";

const Context = createContext({
  footerCalloutContent: {},
  navVariant: "",
  setFooterCalloutContent: () => {},
  setNavVariant: () => {}
});

export const LayoutProvider = ({ children }) => {
  const [footerCalloutContent, setFooterCalloutContent] = useState({});
  const [navVariant, setNavVariant] = useState("dark");

  const value = {
    footerCalloutContent,
    navVariant,
    setFooterCalloutContent,
    setNavVariant
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

LayoutProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const useLayout = () => useContext(Context);
