import styled from "styled-components";
import classnames from "classnames";
import { PropTypes } from "prop-types";
import { theme } from "@src/theme";

export const HalfContainer = styled.div`
  @media ${theme.breakpoints.large} {
    margin-left: auto;
    max-width: 49.8%;
    width: 100%;
  }
`;

export const FullScreenContainer = styled.div`
  min-width: 100vw;
  position: relative;
  margin-left: -50vw;
  left: 50%;
`;

export const ThreeFourthContainer = styled.div`
  width: 100%;
  @media ${theme.breakpoints.large} {
    margin-left: auto;
    max-width: 75.8%;
  }
`;

export const ThreeFourthOffsetContainer = styled.div`
  margin-right: calc(${theme.spacing.lg} * -1);

  @media ${theme.breakpoints.large} {
    margin-left: auto;
    margin-right: calc(${theme.spacing.lg} * -1);
    max-width: 75.8%;
    width: 100%;
  }
`;

export const OffsetLeftContainer = styled.div`
  width: 100%;

  @media ${theme.breakpoints.large} {
    max-width: 96.8%;
    margin-left: calc(${theme.spacing.lg} * -1);
    margin-right: auto;
  }

  @media ${theme.breakpoints.max} {
    max-width: 1260px;
  }
`;

export const OffsetRightContainer = styled.div`
  margin-right: calc(${theme.spacing.sm} * -1);
  width: 100%;

  @media ${theme.breakpoints.large} {
    max-width: 96.8%;
    margin-left: auto;
    margin-right: calc(${theme.spacing.lg} * -1);
  }

  @media ${theme.breakpoints.max} {
    max-width: 1260px;
  }
`;

const Wrapper = styled.div.attrs(
  ({ overlap, mobileOverlap, inner, noPadding }) => ({
    className: classnames({
      overlap,
      inner,
      mobileOverlap,
      noPadding
    })
  })
)`
  margin-left: auto;
  margin-right: auto;
  padding-left: ${theme.spacing.sm};
  padding-right: ${theme.spacing.sm};
  max-width: var(--max-width);
  position: relative;
  width: 100%;

  &.inner {
    max-width: var(--max-width-md);
    padding-left: ${theme.spacing.sm};
    padding-right: ${theme.spacing.sm};

    @media ${theme.breakpoints.notSmall} {
      padding-left: ${theme.spacing.md};
      padding-right: ${theme.spacing.md};
    }

    @media ${theme.breakpoints.max} {
      padding-left: 0;
      padding-right: 0;
    }

    &.noPadding {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &.overlap {
    padding-left: 0;
    padding-right: 0;
    margin-left: calc(${theme.spacing.sm} * -1);
    margin-right: calc(${theme.spacing.sm} * -1);
    max-width: calc(100% + calc(${theme.spacing.sm} * 2));
    width: calc(100% + calc(${theme.spacing.sm} * 2));

    @media ${theme.breakpoints.large} {
      margin-left: calc(${theme.spacing.lg} * -1);
      margin-right: calc(${theme.spacing.lg} * -1);
      max-width: calc(100% + calc(${theme.spacing.lg} * 2));
      width: calc(100% + calc(${theme.spacing.lg} * 2));
    }
  }

  &.mobileOverlap {
    padding-left: 0;
    padding-right: 0;
    margin-left: calc(${theme.spacing.sm} * -1);
    margin-right: calc(${theme.spacing.sm} * -1);
    max-width: calc(100% + calc(${theme.spacing.sm} * 2));
    width: calc(100% + calc(${theme.spacing.sm} * 2));

    @media ${theme.breakpoints.notSmall} {
      margin-left: auto;
      margin-right: auto;
      max-width: 100%;
      width: 100%;
    }
  }
`;

const Container = ({ children, ...props }) => {
  return <Wrapper {...props}>{children}</Wrapper>;
};

Container.propTypes = {
  children: PropTypes.node
};

export default Container;

export const FullWidthContainer = ({ children, ...props }) => {
  return (
    <Wrapper {...props} overlap>
      {children}
    </Wrapper>
  );
};

FullWidthContainer.propTypes = {
  children: PropTypes.node
};

export const MobileFullWidthContainer = ({ children, ...props }) => {
  return (
    <Wrapper {...props} mobileOverlap>
      {children}
    </Wrapper>
  );
};

MobileFullWidthContainer.propTypes = {
  children: PropTypes.node
};
