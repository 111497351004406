import { css } from "styled-components";

const poppins = "Poppins, Arial, Helvetica, sans-serif";
const playfair = "'Playfair Display', garamond, serif";

export const theme = {
  animations: {
    timingFunction: {
      enter: "cubic-bezier(0.65, 0, 0.32, 1.55)",
      exit: "cubic-bezier(0.8, -0.49, 0.36, 1)"
    },
    duration: {
      normal: "500ms",
      medium: "750ms",
      slow: "1000ms"
    }
  },
  breakpoints: {
    // these match the media queries defined in @washingtonpost/site-components
    notSmall: "only screen and (min-width: 768px)",
    medium: "only screen and (min-width: 768px) and (max-width: 1023px)",
    large: "only screen and (min-width: 1024px)",
    max: "only screen and (min-width: 1440px)"
  },
  colors: {
    black: "#111",
    blue: "#3d73d5",
    blueLight: "#cfebec",
    cream: "#edeae5",
    creamLight: "#f9f8f7",
    gray2: "#f7f7f7",
    gray3: "#f0f0f0",
    gray4: "#e9e9e9",
    gray5: "#d5d5d5",
    gray6: "#aaa",
    gray7: "#666",
    green: "#568f91",
    greenDark: "#073221",
    red: "#e73106",
    redFeedback: "#ea0116",
    white: "#fff",

    // not in styleguide
    "blue-25": "rgba(207, 235, 236, 0.25)",
    gray9: "#2a2a2a",
    gray11: "#000",
    advertisingCream: "#eeeae4",
    advertisingBlue: "#c7eced",
    advertisingGreen: "#50B188",
    advertisingPurple: "#3F4868",
    advertisingYellow: "#FBD508"
  },
  fonts: {
    poppins,
    playfair,
    bodyLarge: css`
      font-family: ${poppins};
      font-size: 24px;
      line-height: 1.5;
    `,
    bodyMedium: {
      fontFamily: poppins,
      fontSize: 18,
      lineHeight: 1.5
    },
    bodySmall: css`
      font-family: ${poppins};
      font-size: 16px;
      line-height: 1.5;
    `,
    bodyTiny: {
      fontFamily: poppins,
      fontSize: 12,
      lineHeight: 1.92
    },
    eyebrow: {
      fontFamily: playfair,
      fontSize: 16,
      lineHeight: 1.31
    },
    caption: css`
      font-family: ${poppins};
      font-size: 14px;
      line-height: 1.5;
    `,
    headingSmall: css`
      font-family: ${playfair};
      font-size: 30px;
      font-weight: 700;
      line-height: 1.167;
    `,
    headingMedium: {
      fontFamily: playfair,
      fontSize: 40,
      fontWeight: 700,
      lineHeight: 1.125
    },
    headingLarge: css`
      font-family: ${poppins};
      font-size: 40px;
      font-weight: 700;
      line-height: 1.25;
    `,
    headingExtraLarge: {
      fontFamily: poppins,
      fontSize: 50,
      fontWeight: 700,
      lineHeight: 1.2
    },
    headingExtraExtraLarge: {
      fontFamily: poppins,
      fontSize: 60,
      fontWeight: 700,
      lineHeight: 1.17
    },
    headingExtraExtraExtraLarge: {
      fontFamily: poppins,
      fontSize: 100,
      fontWeight: 700,
      lineHeight: 1.2
    },
    headingEmphasisSmall: {
      fontFamily: playfair,
      fontSize: 30,
      fontStyle: "italic",
      fontWeight: 500,
      lineHeight: 1.17
    },
    headingEmphasisMedium: {
      fontFamily: playfair,
      fontSize: 50,
      fontStyle: "italic",
      fontWeight: 500,
      lineHeight: 1.2
    },
    headingEmphasisLarge: {
      fontFamily: playfair,
      fontSize: 80,
      fontStyle: "italic",
      fontWeight: 500,
      lineHeight: 1.2
    }
  },
  spacing: {
    xxxs: "0.25rem",
    xxs: "0.5rem",
    xs: "1rem",
    sm: "1.5rem",
    md: "2rem",
    lg: "2.5rem",
    xl: "3rem",
    xxl: "4rem",
    xxxl: "5rem",
    xxxxl: "5rem"
  },
  gap: {
    sm: "1rem",
    md: "2rem",
    lg: "3rem",
    xl: "4rem"
  },
  width: {
    max: "1440px"
  }
};
