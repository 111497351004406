import {
  createContext,
  useContext,
  useEffect,
  useLayoutEffect,
  useState
} from "react";
import PropTypes from "prop-types";
import { theme } from "@src/theme";

const breakpointNames = Object.keys(theme.breakpoints);

const queriesToNames = breakpointNames.reduce(
  (acc, name) => ({
    ...acc,
    [theme.breakpoints[name]]: name
  }),
  {}
);

const defaultBreakpoints = breakpointNames.reduce(
  (acc, name) => ({
    ...acc,
    [name]: false
  }),
  {}
);

const Context = createContext(defaultBreakpoints);

export const BreakpointsProvider = ({ children }) => {
  const [breakpoints, setBreakpoints] = useState(defaultBreakpoints);

  const onChange = (e) => {
    const { matches, media } = e;

    const name = queriesToNames[media];

    setBreakpoints((existing) => ({
      ...existing,
      [name]: matches
    }));
  };

  const effectFn = typeof window === "undefined" ? useEffect : useLayoutEffect;

  effectFn(() => {
    const queries = Object.keys(queriesToNames);
    const mqLists = queries.map((query) => window.matchMedia(query));

    setBreakpoints(
      mqLists.reduce(
        (acc, mqList) => ({
          ...acc,
          [queriesToNames[mqList.media]]: mqList.matches
        }),
        {}
      )
    );

    mqLists.forEach((mqList) => {
      if (mqList.addEventListener) {
        mqList.addEventListener("change", onChange);
      } else {
        mqList.addListener(onChange);
      }
    });

    return () => {
      mqLists.forEach((mqList) => {
        if (mqList.addEventListener) {
          mqList.removeEventListener("change", onChange);
        } else {
          mqList.removeListener(onChange);
        }
      });
    };
  }, []);

  return <Context.Provider value={breakpoints}>{children}</Context.Provider>;
};

BreakpointsProvider.propTypes = {
  children: PropTypes.node
};

export const useBreakpoints = () => useContext(Context);
